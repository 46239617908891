import React from 'react';
import { Link } from 'react-router-dom';

import { HeaderContainer } from './containers/HeaderContainer';

export const Header = () => {
    return (
        <HeaderContainer>
            <Link to="/" className="navbar-brand">
                <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto/12th-ave-cafe/12th-ave-cafe-logo_pxr52n.svg" alt="12th Avenue Cafe Logo" className="logo fade-in" />
            </Link>
        </HeaderContainer>
    );
}