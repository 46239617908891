import React from 'react';
import { Helmet } from 'react-helmet';

import { Wrapper } from '../components/containers/Wrapper';
import { ContentContainer } from '../components/containers/ContentContainer';

const Menu = () => {
    return (
        <Wrapper>
            <Helmet>
                <title>12th Avenue Cafe - Menu</title>
                <meta property="og:title" content="12th Avenue Cafe - Menu" />
                <meta property="og:image" content="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/food/IMG_0_cwvep3.jpg" />
                <meta property="og:url" content="https://12thavenuecafe.com/menu" />
            </Helmet>
            <div id="carousel" className="carousel slide" data-ride="carousel">
                <div className="carousel-inner">
                    <div className="carousel-item active">
                        <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/food/IMG_2_bdyzys.jpg" alt="12th Avenue Cafe Carousel Item" />
                    </div>
                    <div className="carousel-item">
                        <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/food/IMG_4_apapuu.jpg" alt="12th Avenue Cafe Carousel Item" />
                    </div>
                    <div className="carousel-item">
                        <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/food/IMG_1_mruvd0.jpg" alt="12th Avenue Cafe Carousel Item" />
                    </div>
                    <div className="carousel-item">
                        <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/food/IMG_5_ywt39x.jpg" alt="12th Avenue Cafe Carousel Item"  />
                    </div>
                </div>
                <a className="carousel-control-prev" href="#carousel" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>
                <a className="carousel-control-next" href="#carousel" role="button" data-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="sr-only">Next</span>
                </a>
            </div>
            <ContentContainer>
                <div className="content">
                    <h2><span>Menu</span></h2>
                    <a href="https://drive.google.com/file/d/10tcJ9sc0SmjRREleIwMVfq9ecPimBQrs/view?usp=sharing" target="_blank" rel="noreferrer noopener">Breakfast Menu</a>
                    <br />
                    <a href="https://drive.google.com/file/d/1AOkca_aeY6ADYAQOMkGBzXORXkkD5QCr/view?usp=sharing" target="_blank" rel="noreferrer noopener">Dinner Menu</a>
                </div>
                <div className="content">
                    <h2><span>Offering:</span></h2>
                    <div className="info text-success">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z" fill="currentColor" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="currentColor" />
                        </svg>
                        <p>Indoor Dining</p>
                    </div>
                    <div className="info text-success">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z" fill="currentColor" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="currentColor" />
                        </svg>
                        <p>Outdoor Dining</p>
                    </div>
                    <div className="info text-success">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z" fill="currentColor" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="currentColor" />
                        </svg>
                        <p>To-go</p>
                    </div>
                    <div className="info text-success">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.2426 16.3137L6 12.071L7.41421 10.6568L10.2426 13.4853L15.8995 7.8284L17.3137 9.24262L10.2426 16.3137Z" fill="currentColor" />
                            <path fillRule="evenodd" clipRule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21Z" fill="currentColor" />
                        </svg>
                        <p>Deliver</p>
                    </div>
                </div>
                <div className="content">
                    <h2><span>Online Ordering:</span></h2>
                    <div className="buttons">
                        <a className="btn btn-grubhub" href="https://www.grubhub.com/restaurant/12th-avenue-cafe-775-nw-gilman-blvd-issaquah/2106625" target="_blank" rel="noreferrer noopener">Order With Grubhub</a>
                        <a className="btn btn-postmates" href="https://postmates.com/merchant/12th-ave-cafe-issaquah-44808" target="_blank" rel="noreferrer noopener">Order With Postmates</a>
                        <a className="btn btn-doordash" href="https://www.doordash.com/store/12th-ave-cafe-issaquah-125281/en-US" target="_blank" rel="noreferrer noopener">Order With Doordash</a>
                        <a className="btn btn-chownow" href="https://eat.chownow.com/discover/restaurant/25302" target="_blank" rel="noreferrer noopener">Order With Chownow</a>
                    </div>
                </div>
            </ContentContainer>
        </Wrapper>
    );
}

export default Menu;