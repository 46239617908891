import React from 'react';

import { CardContainer } from './containers/CardContainer';

export const Card = props => {
    return (
        <CardContainer>
            <a href={props.location.url} target="_blank" rel="noreferrer noopener">
                <img
                    sizes="(min-width: 30em) 28em, 100vw"
                    srcSet={`
                        ${props.location.srcset1},
                        ${props.location.srcset2},
                        ${props.location.srcset3},
                        ${props.location.srcset4},
                        ${props.location.srcset5}
                    `}
                    src={props.location.src} 
                    alt={`${props.location.name} Storefront`} 
                />
            </a>
            <div className="card-info">
                <h4>{props.location.name}</h4>
                <div className="detail">
                    <a href={props.location.addressURL} target="_blank" rel="noreferrer noopener">{props.location.address}</a>
                </div>
                <div className="detail">
                    <a href={`tel:${props.location.phone}`} data-bb-track-label="Location" data-bb-track="button" data-bb-track-on="click" data-bb-track-action="Click" data-bb-track-category="Phone Number">{props.location.phone}</a>
                </div>
                <a href={props.location.url} className="btn btn-infrared" target="_blank" rel="noreferrer noopener">Visit {props.location.name}</a>
            </div>
        </CardContainer>
    );
}