import styled from 'styled-components';

export const CardContainer = styled.div`
    border: 1px solid var(--dark);
    background: var(--light);
    color: var(--dark);
    margin: 16px 0;

    .card-info {
        padding: 16px;
    }

    .detail {
        padding: 4px 0;
    }

    .btn {
        margin: 8px 0;
    }

    @media screen and (min-width: 1024px) {
        max-width: 48%;
        margin: 8px;
    }
`;