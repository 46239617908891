import styled from 'styled-components';

export const NavbarContainer = styled.nav`
    background: #222222;
    border-bottom: 1px solid var(--dark);

    svg {
        fill: var(--light);
    }

    .navbar-nav {
        margin: 0 auto;
    }

    .nav-link {
        font-family: 'Racing Sans One', cursive;
        color: var(--light);
        text-transform: uppercase;
        border-bottom: 1px solid transparent;
        transition: all 0.25s linear;
        &:hover {
            color: var(--infrared);
            border-bottom: 1px solid var(--infrared);
        }
    }

    @media screen and (max-width: 575.98px) {
        .navbar-nav {
            height: 100vh;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .nav-link {
            font-size: 3em;
        }
    }

    @media screen and (min-width: 576px) {
        .nav-link {
            font-size: 1.25em;
        }
    }
`;