import styled from 'styled-components';

export const HeroContainer = styled.div`
    background: url("https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/food/IMG_0_cwvep3.jpg");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    opacity: 90%;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    overflow-x: hidden;

    h1 {
        color: var(--infrared);
    }

    @media screen and (max-width: 576px) {
        h1 {
            font-size: 3.5em;
        }
    }
`;