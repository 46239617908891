import React from 'react';
import { Link } from 'react-router-dom';

import { NavbarContainer } from './containers/NavbarContainer';

export const Navbar = () => {
    return (
        <NavbarContainer className="navbar navbar-expand-lg sticky-top" role="navigation">
            <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarToggler" aria-controls="navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon">
                    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44772 22 6C22 6.55228 21.5523 7 21 7H3C2.44772 7 2 6.55228 2 6Z" />
                        <path d="M2 12.0322C2 11.4799 2.44772 11.0322 3 11.0322H21C21.5523 11.0322 22 11.4799 22 12.0322C22 12.5845 21.5523 13.0322 21 13.0322H3C2.44772 13.0322 2 12.5845 2 12.0322Z" />
                        <path d="M3 17.0645C2.44772 17.0645 2 17.5122 2 18.0645C2 18.6167 2.44772 19.0645 3 19.0645H21C21.5523 19.0645 22 18.6167 22 18.0645C22 17.5122 21.5523 17.0645 21 17.0645H3Z"  />
                    </svg>
                </span>
            </button> 
            <div className="collapse navbar-collapse" id="navbarToggler">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <Link to="/" className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/menu" className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Menu</Link>
                    </li>
                    <li className="nav-item">
                        <Link to="/contact" className="nav-link" data-toggle="collapse" data-target=".navbar-collapse.show">Contact</Link>
                    </li>
                </ul>
            </div>
        </NavbarContainer>
    );
}