import styled from 'styled-components';

export const FooterContainer = styled.div`
    background: var(--dark-red);

    a {
        color: var(--light);
        font-size: 1.25rem;
        &:hover {
            color: var(--dark);
        }
    }

    ul {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
    }

    li {
        padding: 24px 8px 24px 16px;
    }
`;