import styled from 'styled-components';

export const HeaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 
    background: #222222;

    .logo {
        width: 352px;
        height: 184px;
        margin: 8px 0;
    }

    @media screen and (max-width: 576px) {
        visibility: hidden;
        width: 0;
        height: 0;
    }
`;