import React from 'react';
import { Helmet } from 'react-helmet';

import { Wrapper } from '../components/containers/Wrapper';
import { ContentContainer } from '../components/containers/ContentContainer';
import { LocationCards } from '../components/LocationCards';

const Contact = () => {
    return (
        <Wrapper>
            <Helmet>
                <title>12th Avenue Cafe - Contact Us</title>
                <meta property="og:title" content="12th Avenue Cafe - Contact Us" />
                <meta property="og:image" content="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/12th-ave-cafe-storefront.png" />
                <meta property="og:url" content="https://12thavenuecafe.com/menu" />
            </Helmet>
            <img src="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/12th-ave-cafe-storefront.png" alt="12th Avenue Cafe Storefront" />
            <ContentContainer>
                <div className="content">
                    <h2><span>Contact Us</span></h2>
                    <a href="https://www.google.com/maps/search/775+NW+Gilman+Blvd,+Issaquah,+WA+98027" target="_blank" rel="noreferrer noopener">775 NW Gilman Blvd, Issaquah, WA 98027</a>
                    <br />
                    <a href="tel:425-392-5975" data-bb-track-label="Location" data-bb-track="button" data-bb-track-on="click" data-bb-track-action="Click" data-bb-track-category="Phone Number">425-392-5975</a>
                </div>
                <div className="content">
                    <h2><span>Hours</span></h2>
                    <p>Monday - Saturday: 7:00 AM - 8:00 PM</p>
                    <p>Sunday: 7:00 AM - 3:00 PM</p>
                </div>
                <div className="content">
                    <h2><span>Other Locations</span></h2>
                    <p className="mt-4">Come visit us at any one of our other restaurants!</p>
                    <LocationCards />
                </div>
            </ContentContainer>
        </Wrapper>
    );
}

export default Contact;