import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
    :root {
        --light: #f0f0f0;
        --dark: #000;
        --dark-red: #a3232e;
        --infrared: #ff6b6b;
        --grubhub: #F01A31;
        --doordash: #FB0007;
        --chownow: #DE5C54;
    }

    html.wf-loading * {
        opacity: 0;
    }

    *,
    *::after,
    *::before {
        box-sizing: border-box;
    }

    body {
        background: #222222;
        color: var(--light);
        font-family: 'Roboto', BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial, sans-serif;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: 'Racing Sans One', cursive;
    }

    h1 {
        font-size: 4.5em;
        text-transform: uppercase;
    }

    h2 {
        font-size: 3em;
    }

    h3 {
        font-size: 2.5em;
    }

    h4 {
        font-size: 2em;
    }

    h5 {
        font-size: 1.5em;
    }

    h6 {
        font-size: 1em;
    }

    a {
        color: var(--infrared);
        &:hover {
            color: var(--infrared);
            text-decoration: underline;
        }
    }

    a:active, a:focus, button:active, button:focus {
        outline: none;
        border: none;
    }

    img {
        width: 100%;
    }

    .btn {
        padding: 16px 32px;
        font-weight: 900;
        text-transform: uppercase;
    }

    .btn-dark-red {
        background: var(--dark-red);
        border: 1px solid var(--dark-red);
        color: var(--light);
        &:hover {
            background: transparent;
            color: var(--dark-red);
        }
    }

    .btn-infrared {
        background: var(--infrared);
        border: 1px solid var(--infrared);
        color: var(--light);
        &:hover {
            background: transparent;
            color: var(--infrared);
        }
    }

    .btn-postmates {
        background: var(--dark);
        border: 1px solid var(--dark);
        color: var(--light);
        &:hover {
            background: transparent;
            border: 1px solid var(--light);
            color: var(--light);
        }
    }
    
    .btn-grubhub {
        background: var(--grubhub);
        border: 1px solid var(--grubhub);
        color: var(--light);
        &:hover {
            background: transparent;
            color: var(--grubhub);
        }
    }

    .btn-doordash {
        background: var(--doordash);
        border: 1px solid var(--doordash);
        color: var(--light);
        &:hover {
            background: transparent;
            color: var(--doordash);
        }
    }

    .btn-chownow {
        background: var(--light);
        border: 1px solid var(--light);
        color: var(--chownow);
        &:hover {
            background: transparent;
            color: var(--light);
        }
    }

    @media screen and (max-width: 576px) {
        .btn {
            width: 100%;
        }
    }

    /* ----------------------------------------------
    * Generated by Animista on 2021-1-12 15:4:45
    * Licensed under FreeBSD License.
    * See http://animista.net/license for more info. 
    * w: http://animista.net, t: @cssanimista
    * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation fade-in
     * ----------------------------------------
     */
    @-webkit-keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .fade-in {
        -webkit-animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
                animation: fade-in 1.5s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    /**
     * ----------------------------------------
     * animation text-shadow-pop-bl
     * ----------------------------------------
     */
    @-webkit-keyframes text-shadow-pop-bl {
        0% {
            text-shadow: 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222;
            -webkit-transform: translateX(0) translateY(0);
                    transform: translateX(0) translateY(0);
        }
        100% {
            text-shadow: -1px 1px #222222, -2px 2px #222222, -3px 3px #222222, -4px 4px #222222, -5px 5px #222222, -6px 6px #222222, -7px 7px #222222, -8px 8px #222222;
            -webkit-transform: translateX(8px) translateY(-8px);
                    transform: translateX(8px) translateY(-8px);
        }
    }
    @keyframes text-shadow-pop-bl {
        0% {
            text-shadow: 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222, 0 0 #222222;
            -webkit-transform: translateX(0) translateY(0);
                    transform: translateX(0) translateY(0);
        }
        100% {
            text-shadow: -1px 1px #222222, -2px 2px #222222, -3px 3px #222222, -4px 4px #222222, -5px 5px #222222, -6px 6px #222222, -7px 7px #222222, -8px 8px #222222;
            -webkit-transform: translateX(8px) translateY(-8px);
                    transform: translateX(8px) translateY(-8px);
        }
    }

    .text-shadow-pop-bl {
        -webkit-animation: text-shadow-pop-bl 1.75s both;
                animation: text-shadow-pop-bl 1.75s both;
    }
`;