import React from 'react';

import { Card } from './Card';
import { LocationCardsContainer } from './containers/LocationCardsContainer';

export const LocationCards = () => {
    const locationInfo = [
        {
            id: 1, 
            name: 'The Egg & Us', 
            srcset1: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_256/the-egg-and-us/issaquah-storefront.webp 256w',
            srcset2: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_512/the-egg-and-us/issaquah-storefront.webp 512w',
            src: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto/the-egg-and-us/issaquah-storefront.webp',
            address: '375 NW Gilman Blvd, Issaquah, WA 98027', 
            addressURL: 'http://www.google.com/maps/search/375%20NW%20Gilman%20Blvd%20Issaquah%2C%20Issaquah%2C%20WA%2098027',
            phone: '425-557-1919',
            url: 'https://theeggandus.com'
        },
        {
            id: 3,
            name: 'Pete\'s Eggnest',
            srcset1: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_256/the-egg-and-us/petes-eggnest-storefront.webp 256w',
            srcset2: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto,w_512/the-egg-and-us/petes-eggnest-storefront.webp 512w',
            src: 'https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,dpr_auto/the-egg-and-us/petes-eggnest-storefront.webp',
            address: '7717 Greenwood Ave N, Seattle, WA 98103',
            addressURL: 'http://www.google.com/maps/search/7717%20Greenwood%20Ave%20N%2C%20Seattle%2C%20WA%2098103',
            phone: '206-784-5348',
            url: 'https://peteseggnest.com'
        }
    ];

    const locations = locationInfo.map(location => {
        return (
            <Card location={location} key={location.id} />
        );
    })

    return (
        <LocationCardsContainer>
            {locations}
        </LocationCardsContainer>
    );
}