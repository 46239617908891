import styled from 'styled-components';

export const LocationCardsContainer = styled.div`
    @media screen and (min-width: 1024px) {
        display: flex;
        flex-flow: row wrap;
        
        .react-reveal {
            width: 50%;
            display: flex;
            flex-flow: row wrap;
        }
    }
`;