import styled from 'styled-components';

export const ContentContainer = styled.section`
    margin: 16px;

    h2 {
        border-bottom: 1px solid var(--light);
        line-height: 0.1em;
        margin: 24px 0;
    }

    span {
        background: #222222;
        padding: 0 10px 0 0;
    }
    
    .content {
        margin: 32px 0;
    }

    .info {
        display: flex;
        flex-direction: row;
        svg {
            margin: 0 8px 0 0;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        .btn {
            margin: 8px 0;
        }
    }

    @media screen and (min-width: 768px) {
        margin: 16px 20%;
       
        h1 {
            font-size: 4rem;
        }

        .buttons {
            width: 50%;
        }
    }
`;