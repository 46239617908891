import React from 'react';
import { Helmet } from 'react-helmet';

import { Wrapper } from '../components/containers/Wrapper';
import { HeroContainer } from '../components/containers/HeroContainer';
import { ContentContainer } from '../components/containers/ContentContainer';
import { LocationCards } from '../components/LocationCards';

const Home = () => {
    return (
        <Wrapper>
            <Helmet>
                <meta property="og:title" content="12th Avenue Cafe" />
                <meta property="og:image" content="https://res.cloudinary.com/dwot7f2oz/image/upload/q_auto,f_auto,w_auto,dpr_auto/12th-ave-cafe/food/IMG_0_cwvep3.jpg" />
                <meta property="og:url" content="https://12thavenuecafe.com" />
            </Helmet>
            <HeroContainer>
                <h1 className="text-shadow-pop-bl">Welcome to<br /> 12th Avenue Cafe!</h1>
            </HeroContainer>
            <ContentContainer>
                <div className="content">
                    <h2><span>About Us</span></h2>
                    <p>
                        Established in 1991, 12th Avenue Cafe prides itself in being the neighborhood diner you can count on. From the beginning, we have always strived to provide a friendly, reliable, and consistently great dining experience. 
                        It is always our goal to use the freshest ingredients and provide hearty portions at fair, affordable prices to ensure our guests have a satisfying experience every time they leave our diner. 
                    </p>
                </div>
                <div className="content">
                    <h2><span>Location</span></h2>
                    <a href="https://www.google.com/maps/search/775+NW+Gilman+Blvd,+Issaquah,+WA+98027" target="_blank" rel="noreferrer noopener">775 NW Gilman Blvd, Issaquah, WA 98027</a>
                    <br />
                    <a href="tel:425-392-5975" data-bb-track-label="Location" data-bb-track="button" data-bb-track-on="click" data-bb-track-action="Click" data-bb-track-category="Phone Number">425-392-5975</a>
                </div>
                <div className="content">
                    <h2><span>Hours</span></h2>
                    <p>Monday - Saturday: 7:00 AM - 8:00 PM</p>
                    <p>Sunday: 7:00 AM - 3:00 PM</p>
                </div>
                <div className="content">
                    <p className="mt-4">Come visit us at any one of our other restaurants!</p>
                    <LocationCards />
                </div>
            </ContentContainer>
        </Wrapper>
    );
}

export default Home;