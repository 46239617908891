import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';

import { GlobalStyles } from './assets/styles/global';
import ScrollToTop from './hooks/ScrollToTop';

import { Header } from './components/Header';
import { Navbar } from './components/Navbar';
import { Footer } from './components/Footer';
import Home from './pages/Home';
import Menu from './pages/Menu';
import Contact from './pages/Contact';

const NoMatch = () => {
  let location = useLocation();

  return (
    <Redirect to="/" />
  );
}

const App = () => {
  return (
    <>
      <GlobalStyles />
      <Router>
        <ScrollToTop />
        <Header />
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/menu" component={Menu} />
          <Route path="/contact" component={Contact} />
          <Route path="*" component={NoMatch} />
        </Switch>
        <Footer />
      </Router>
    </>
  );
}

export default App;