import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faYelp } from '@fortawesome/free-brands-svg-icons';

import { FooterContainer } from './containers/FooterContainer';

export const Footer = () => {
    return (
        <FooterContainer>
            <ul className="list-inline">
                <li>
                    <a href="https://www.facebook.com/12tvavcafe" target="_blank" rel="noreferrer noopener">
                        <FontAwesomeIcon icon={faFacebookSquare} />
                    </a>
                </li>
                <li>
                    <a href="https://www.yelp.com/biz/12th-ave-cafe-issaquah" target="_blank" rel="noreferrer noopener">
                        <FontAwesomeIcon icon={faYelp} />
                    </a>
                </li>
            </ul>
        </FooterContainer>
    );
}